<template>
  <app-list-view
    server-side
    show-group-by
    app="contact"
    model="personaldocument"
    api-url="contact/personal-doc/"
    :title="$t('menu.otherLicense')"
    :createTo="{ name: 'otherLicenseCreate' }"
    :editTo="{ name: 'otherLicenseEdit' }"
    :headers="headers"
    :query="{ document_template_id__type: 'li' }"
  >
    <template v-slot:item.type="{ item }">
      {{ $t(`label.${item.type}`) }}
    </template>
  </app-list-view>
</template>

<script>
import AppListView from '@components/AppListView'

export default {
  name: 'other-document',
  components: {
    AppListView
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t('fields.documentName'),
          value: 'document_name',
          mongo: true
        },
        {
          text: this.$t('fields.contact'),
          value: 'contact_ids',
          chips: {
            color: 'secondary',
            icon: 'mdi-account-circle-outline',
            value: 'name',
            router: this.contactRouter
          },
          groupable: false,
          hideFilter: true
        },
        { text: this.$t('fields.type'), value: 'type' },
        {
          text: this.$t('fields.documentTemplate'),
          value: 'document_template_id.name'
        },
        { text: this.$t('fields.state'), value: 'state' },
        {
          text: this.$t('fields.customerCompany'),
          value: 'customer_company_id.name'
        },
        {
          text: this.$t('fields.active'),
          value: 'active',
          check: true,
          groupable: false,
          hideFilter: true
        },
        {
          text: this.$t('label.action'),
          value: 'actions',
          sortable: false,
          groupable: false,
          hideFilter: true
        }
      ]
    }
  },
  methods: {
    contactRouter(item) {
      return {
        name: 'contactEdit',
        params: { id: item.id }
      }
    }
  }
}
</script>
